<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <div class="app-outer">
    <div class="header-wrapper" v-if="0">
      <div class="header">
        <div class="header-logo"><a href="https://stroetmann-saat.de/" target="_blank"><img src="@/assets/logo.svg" /></a></div>
        <div class="header-menu-icon" @click="toggleNavi">
          <span class="header-menu-line"></span>
          <span class="header-menu-line"></span>
          <span class="header-menu-line short"></span>
        </div>
        <nav class="header-menu" v-show="this.naviActive">
          <ul>
            <li>Navigationspunkt?</li>
            <li>Navigationspunkt?</li>
            <li>Navigationspunkt?</li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="banner-wrapper" v-if="0">
      <div class="banner-title">Der Stroetmann Produktfinder</div>
      <div class="banner-main">
        <div class="banner-slides">
          <div class="banner-slide"><img src="@/assets/icon-blaetter.svg" /> </div>
          <div class="banner-slide"><img src="@/assets/icon-gruenland.svg" /> </div>
          <div class="banner-slide"><img src="@/assets/icon-mais.svg" /> </div>
        </div>
        <div class="banner-highlight"><img src="@/assets/banner-highlight.png" /></div>
      </div>
      <div class="banner-subtitle-wrapper">
        <div class="banner-subtitle">{{ $route.meta.bannertitle }}</div>
        <div class="banner-navigation">
          <div class="prev"></div>
          <div class="next"></div>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<style lang="scss">
@font-face {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 400;
    src: local('Spartan'), local('Spartan'),
    url('assets/fonts/Spartan-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    src: local('Spartan'), local('Spartan'),
    url('assets/fonts/Spartan-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
}
body { background-color: #E4E4E4; }
.app-outer {
  display: flex; flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  .header-wrapper {
    background-color: #ffffff;
    padding: 12px 24px;
    position: relative;
    .header {
      display: flex; align-items: center; justify-content: space-between;
      // .header-logo {
      // }
      .header-menu-icon {
        border: 1px solid #DEDEDE;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        width: 22px; height: 22px;
        .header-menu-line {
          background-color: #007858;
          display: inline-block;
          width: 16px; height: 2px;
          position: absolute; left: 3px;
          &:first-child{
            top: 4px;
          }
          &:nth-child(2){
            top: 10px;
          }
          &:nth-child(3){
            top: 16px;
          }
          &.short { width: 10px; }
        }
      }
      .header-menu {
        background-color: #ffffff;
        position: absolute; top: 100%; left: 0; right: 0;
        z-index: 10;
        ul {
          list-style: none;
        }
      }
    }
  }
  .banner-wrapper {
    background-color: #E73136;
    padding: 20px 12px 0;
    position: relative;
    color: #FFFFFF;
    .banner-title {
      font-size: 18px; font-weight: 700; line-height: 30px;
      text-transform: uppercase;
    }
    .banner-subtitle-wrapper {
      padding-left: 40px; padding-right: 40px;
      position: absolute; bottom: 0; left: 0; right: 0;
      .banner-subtitle {
        font-size: 22px;
        text-align: center;
        text-transform: uppercase;
      }
      .banner-navigation {
        padding: 0 20px;
        position: absolute; top: 0; bottom: 0; left: 0; right: 0;
        display: flex; align-items: center; justify-content: space-between;
        .prev, .next {
          position: relative;
          &::before {
            content: '';
            border-color: #FFFFFF #FFFFFF transparent transparent;
            border-width: 2px 2px 0 0;
            border-style: solid;
            display: inline-block;
            padding: 5px;
            position: absolute; top: 0;
            -webkit-backface-visibility: hidden
          }
        }
        .prev::before {
          left: 0; margin-left: 8px;
          -webkit-transform: translate(-50%, -50%) rotate(225deg);
          -moz-transform: translate(-50%, -50%) rotate(225deg);
          -ms-transform: translate(-50%, -50%) rotate(225deg);
          -o-transform: translate(-50%, -50%) rotate(225deg);
          transform: translate(-50%, -50%) rotate(225deg);
        }
        .next::before {
          right: 0; margin-right: 8px;
          -webkit-transform: translate(-50%, -50%) rotate(45deg);
          -moz-transform: translate(-50%, -50%) rotate(45deg);
          -ms-transform: translate(-50%, -50%) rotate(45deg);
          -o-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
    .banner-main {
      display: inline-block;
      position: relative;
      .banner-slides {
        display: flex; align-items: center; justify-content: space-between;
        position: absolute; top: 16px; left: 0; right: 0; 
        .banner-slide {

        }
      }
      .banner-highlight {
        margin: 0 auto;
      }
    }
  }
  .app-view {
    min-height: 100%;
    display: flex; flex-direction: column;
    position: relative;
  }
}
#app {
  font-family: 'Spartan', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  // max-width: 414px;
}

/* vueforms/slider */
.filter-config {
  .slider-horizontal {
    height: 12px;
    margin-top: 24px; margin-bottom: 24px;
    .slider-base {
      //Ganzer Zeitstrahl
      background-color: #E73136;
      .slider-connects {
        .slider-connect {
          //Raum zwischen zwei Punkten, "aktiv"
          background-color: #007858;
        }
      }
      .slider-origin {
        &:nth-child(2) {
          //erster Regler Wrapper
          .slider-handle::before {
            left: 7px;
          }
          .slider-handle::after {
            left: 12px; 
          }
        }
        &:last-child {
          //zweiter Regler Wrapper
        }
        .slider-handle {
          //Regler
          border: 1px solid #C3C3C3; border-radius: 3px;
          width: 22px; height: 30px;
          top: -10px; right: -11px;
          &::before { content: ''; display: block; position: absolute; top: 9px; left: 12px; background-color: #DEDEDE; width: 3px; height: 13px; }
          &::after { content: ''; display: block; position: absolute; top: 9px; left: 8px; background-color: #DEDEDE; width: 2px; height: 13px; }
        }
      }
    }
  }
}

* { box-sizing: border-box; }
</style>

<script>
export default {
  name: 'Main',
  data(){
    return {
      naviActive: false
    }
  },
  methods: {
    toggleNavi: function(){
      this.naviActive = !this.naviActive
    }
  }
}
</script>